import React, {Fragment} from "react";
import styled from "styled-components";
import SidebarItem from "./SidebarItem";
import {Button} from "components/forms";
import { SideBarMenuItem } from "components/navigation/Sidebar/types";
import { useNavigate } from "react-router-dom";
import {Heading3} from "styles/Typography";

const SubmenuSidebar = ({selectedItem}: {selectedItem: SideBarMenuItem }):JSX.Element => {

    const navigate = useNavigate();
    

    return (
        <SidebarContainer>
            <SidebarHeader>
                <Button type="tertiary" icon={'arrow-left-s-line'} onClick={()=>navigate('/')}>
                    <Heading3>{selectedItem.label}</Heading3>
                </Button>
            </SidebarHeader>
            <SidebarMenu>
                {selectedItem.subMenu.map((item) => (
                    <Fragment key={item.path}>
                        <SidebarItem
                            icon={item.icon}
                            linkTo={`${selectedItem.path}/${item.path}`}
                            label={item.label}
                            expanded={true}
                        />
                        {item.separate && <Separator/>}
                    </Fragment>
                ))}
            </SidebarMenu>
        </SidebarContainer>
    );
};

export default SubmenuSidebar;

const SidebarContainer = styled.div`
`;

const SidebarHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 16px 0 16px;
    padding-bottom: 20px;
`;

const SidebarMenu = styled.div`
    flex: 1;
    margin: 0 16px;
`;

const Separator = styled.div`
    border-bottom: 1px solid #E3E3E5;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 16px 0;
`;
