const BACKEND_URL = (() => {
    if (process.env.REACT_APP_ENV === 'production') {
        return 'https://api.spryplan.com';
    }
    return 'http://localhost:8000';
})();

const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
    };
};

const handleResponse = async (response) => {
    if (!response.ok) {
        const errorData = await response.json();

        if (response.status === 422) {
            const errors = errorData.detail.reduce((acc, curr) => {
                const key = curr.loc.slice(1).join('.');
                acc[key] = curr.msg.split(", ")[1];
                return acc;
            }, {});

            throw new Error(JSON.stringify({ status: response.status, errors }));
        }

        throw new Error(
            JSON.stringify({
                status: response.status,
                message: errorData.message || 'Something went wrong',
            })
        );
    }
    return response.json();
};

export const getRequest = async (endpoint, params = {}) => {
    const url = new URL(`${BACKEND_URL}${endpoint}`);

    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const response = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
        credentials: "include",
    });

    return handleResponse(response);
};

export const postRequest = async (endpoint, data) => {
    const response = await fetch(`${BACKEND_URL}${endpoint}`, {
        method: 'POST',
        headers: getHeaders(),
        credentials: "include",
        body: JSON.stringify(data),
    });

    return handleResponse(response);
};

export const putRequest = async (endpoint, data) => {
    const response = await fetch(`${BACKEND_URL}${endpoint}`, {
        method: 'PUT',
        headers: getHeaders(),
        credentials: "include",
        body: JSON.stringify(data),
    });

    return handleResponse(response);
};

export const deleteRequest = async (endpoint) => {
    const response = await fetch(`${BACKEND_URL}${endpoint}`, {
        method: 'DELETE',
        headers: getHeaders(),
        credentials: "include",
    });

    return handleResponse(response);
};

const api = {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
};

export default api;
