import React, {Fragment, useState} from "react";
import styled from "styled-components";
import SidebarItem from "./SidebarItem";
import {Icon, Button} from "components/forms";
import sidebarMenuConfig from "configs/sidebarMenuConfig";

const DefaultSidebar = ():JSX.Element => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [isHoveredSideBar, setIsHoveredSideBar] = useState(false);

    const toggleSideBar = () => {
        setIsExpanded(!isExpanded);
        setIsHoveredSideBar(false);
    }

    // all except the last item
    const mainMenu = sidebarMenuConfig.slice(0, -1);
    // last item
    const settingsItem = sidebarMenuConfig.at(-1);

    return (
        <SidebarContainer
            onMouseEnter={() => setIsHoveredSideBar(true)}
            onMouseLeave={() => setIsHoveredSideBar(false)}
        >
            <SidebarHeader>
                {(isExpanded || !isHoveredSideBar) && <Icon size={32} type="logo"/>}
                {isHoveredSideBar &&
                    <Button icon={isExpanded ? "expand-left-line" : "expand-right-line"} onClick={toggleSideBar} type="tertiary"/>
                }
            </SidebarHeader>
            <SidebarMenu>
                {mainMenu.map((item) => (
                    <Fragment key={item.path}>
                        <SidebarItem
                            icon={item.icon}
                            linkTo={item.path}
                            label={item.label}
                            expanded={isExpanded}
                        />
                        {item.separate && <Separator/>}
                    </Fragment>
                ))}
            </SidebarMenu>
            <SidebarFooter>
                <SidebarItem
                    icon={settingsItem.icon}
                    label={settingsItem.label}
                    linkTo={settingsItem.path}
                    expanded={isExpanded}
                />
            </SidebarFooter>
        </SidebarContainer>
    );
};

export default DefaultSidebar;

const SidebarContainer = styled.div`
`;

const SidebarHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 16px 0 16px;
    padding-bottom: 20px;
`;

const SidebarMenu = styled.div`
    flex: 1;
    margin: 0 16px;
`;

const SidebarFooter = styled.div`
    margin: 0 16px 16px 16px;
    position:absolute;
    bottom:0;
`;

const Separator = styled.div`
    border-bottom: 1px solid #E3E3E5;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 16px 0;
`;
