import { SideBarMenu } from "components/navigation/Sidebar/types";

const sidebarMenuConfig:SideBarMenu = [
  {icon: "group-3-line", label: "Team view", path: "team_view"},
  {icon: "account-circle-line", label: "Personal view", path: "personal_view", separate: true},
  {icon: "file-list-2-line", label: "Agenda", path: "agenda"},
  {icon: "emotion-happy-line", label: "Feedback loop", path: "feedback_loop"},
  {icon: "settings-line", label: "Settings", path: "settings", subMenu: [
      {label: "Members", path: "members"},
      {label: "Teams", path: "teams"},
      {label: "Departments", path: "departments"},
      {label: "Wages", path: "wages", separate: true},
      {label: "Profile", path: "profile"},
      {label: "Notifications", path: "notifications"},
      {label: "Billing", path: "billing"},
      {label: "Plans", path: "plans"},
      {label: "Integrations", path: "integrations"},
  ]},
];

export default sidebarMenuConfig;
