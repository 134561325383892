import React from 'react';
import {
    RouterProvider,
    createBrowserRouter,
    redirect,
    Navigate,
} from "react-router-dom";

import LogInLayout from "components/layout/LogInLayout";
import LogInPage from "pages/login/logIn";
import SingUpPage from "pages/login/signUp"

import ProtectedLayout from "components/layout/ProtectedLayout"
import TeamLayout from "components/layout/TeamLayout";

import OverviewPage from "pages/team_view/overview"
import Insights from "pages/team_view/Insights";

import AgendaPage from "pages/agenda";
import AddEditTemplatePopUp from "pages/agenda/add_edit/AddEditTemplatePopUp";
import DeleteTemplatePopUp from "pages/agenda/delete/DeleteTemplatePopUp";
import FeedbackLoop from "pages/FeedbackLoop";
import PersonalView from "pages/PersonalView";
import Settings from "pages/Settings";
import NotFound from "pages/NotFound";


import {ThemeProvider} from 'styled-components';
import {theme} from 'styles/theme';
import {getRequest} from "./utils/api";


const router = createBrowserRouter([
    {
        path: '/',
        loader: loginLoader,
        element: <LogInLayout/>,
        children: [
            {
                path: "login",
                element: <LogInPage/>,
            },
            {
                path: "signup",
                element: <SingUpPage/>,
            }
        ]
    },
    {
        path: "/",
        loader: protectedLoader,
        element: <ProtectedLayout/>,
        errorElement: <NotFound/>,
        children: [
            {
                index: true,
                element: <Navigate to="team_view" replace/>,
            }, {
                path: "team_view",
                element: <TeamLayout/>,
                children: [
                    {
                        index: true,
                        element: <Navigate to="overview" replace/>,
                    }, {
                        path: "overview",
                        element: <OverviewPage/>,
                    }, {
                        path: "insights",
                        element: <Insights/>,
                    }
                ]
            }, {
                path: "agenda",
                element: <AgendaPage/>,
                children: [
                    {
                        path: "add",
                        element: <AddEditTemplatePopUp/>,
                    }, {
                        path: ":agenda_template_id/edit",
                        element: <AddEditTemplatePopUp/>,
                    }, {
                        path: ":agenda_template_id/delete",
                        element: <DeleteTemplatePopUp/>,
                    }
                ]
            }, {
                path: "feedback_loop",
                element: <FeedbackLoop/>,
            }, {
                path: "personal_view",
                element: <PersonalView/>,
            }, {
                path: "settings",
                children: [
                    {
                        index: true,
                        element: <Navigate to="members" replace />,
                    },{
                        path: "members",
                        element: <Settings />,
                    },{
                        path: "teams",
                        element: <h3>Teams</h3>,
                    },{
                        path: "departments",
                        element: <h3>Departments</h3>,
                    },{
                        path: "wages",
                        element: <h3>Wages</h3>,
                    }
                ]
            },
        ]
    },

]);


function App() {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router}/>
        </ThemeProvider>
    );
}


async function loginLoader() {
    try {
        await getRequest("/auth/");
        return redirect("/");
    } catch (error) {
        return null;
    }

}

async function protectedLoader() {
    try {
        await getRequest("/auth/");
        return null;
    } catch (error) {
        return redirect("/login");
    }
}

export default App;
