import React from "react";
import styled, {css} from "styled-components";
import {Icon} from "components/forms";
import {useNavigate, useLocation} from "react-router-dom";
import {Subheader1} from "styles/Typography"

const SidebarItem = ({icon, label, expanded, linkTo}):JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname.includes(linkTo);
    const getIconType = () => {
        if (isActive) {
            return icon.replace('-line', '-fill');
        } else {
            return icon;
        }
    };

    return (
        <ItemContainer
            $isActive={isActive}
            onClick={() => {
                navigate(linkTo)
            }}
        >
            {icon && <Icon type={getIconType()} size={18}/>}
            {expanded && <SidebarText>{label}</SidebarText>}
        </ItemContainer>
    );
};

export default SidebarItem;

const menuItemStyles = {
    false: css`
        color: ${({theme}) => theme.colors.Neutral["70"]};

        &:hover {
            color: ${({theme}) => theme.colors.Neutral["80"]};
            background-color: ${({theme}) => theme.colors.Neutral["25"]};
        }
    `,
    true: css`
        color: ${({theme}) => theme.colors.Neutral["80"]};
        background-color: ${({theme}) => theme.colors.Neutral["30"]};
    `,
}
interface ItemContainerProps {
    $isActive: boolean;
}

const ItemContainer = styled.div<ItemContainerProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 38px;
    border-radius: 6px;
    padding: 0 10px;
    margin-bottom: 4px;
    ${({$isActive}) => menuItemStyles[$isActive.toString()]}
`;

const SidebarText = styled(Subheader1)`
    margin-left: 8px;
    width: 145px;
`;
