import {Button} from "components/forms";
import React from "react";
import {getRequest} from "utils/api";
import {useNavigate} from "react-router-dom";

const Settings = () => {
    const navigate = useNavigate();
    const handleSignout = async () => {
        await getRequest("/auth/logout/");
        navigate("/login");
    };
    const handle =  () =>{
        console.log(1123)
    }
    const types = ["primary", "secondary", "tertiary", "black"]

    return (
        <>
            <p>Settings</p>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                margin: "20px"
            }}>
                <Button icon="google-color" onClick={handleSignout} text="Sign Out"/>
            </div>
            {
                types.map(type => (
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        margin: "20px"
                    }}>
                        <Button icon="search-line" onClick={handle} text="Button" type={type} size='medium'/>
                        <Button icon="search-line" onClick={handle} text="Button" type={type} size='medium' disabled={true}/>
                        <Button icon="search-line" onClick={handle} text="" type={type} size='medium' />
                        <Button icon="search-line" onClick={handle} text="Button" type={type} />
                        <Button icon="search-line" onClick={handle} text="Button" type={type}  disabled={true}/>
                        <Button icon="search-line" onClick={handle} text="" type={type} />
                    </div>
                ))
            }


        </>
    );
}

export default Settings;