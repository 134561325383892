import React from 'react';
import styled from 'styled-components';
import Logo from 'assets/images/logo_image.svg';
import {ButtonLogIn} from "components/forms";
import {getRequest} from "utils/api";

const LogInPage = () => {
    const handleCLickPrivacyPolicy = (e) => {
        e.preventDefault();
        window.open('https://spryplan.com/privacy_policy', '_blank');
    };

    const handleClickTermsOfUse = (e) => {
        e.preventDefault();
        window.open('https://spryplan.com/terms_of_use', '_blank');
    };
    const handleLogin = async (e) => {
         e.preventDefault();
        const result = await getRequest("/auth/google");
        console.log(result)
        if (result?.uri) {
             window.location.assign(result.uri);
        }
    };


    return (
        <LoginContainer>
            <LogoImage src={Logo}/>
            <TextHeading>Log in to get all the features</TextHeading>
            <ChromeLogin>
                <ButtonLogIn onClick={handleLogin}/>
            </ChromeLogin>
            <UserInfo>
                <Terms>
                    By using Spry Plan you agree to the{' '}
                    <CustomLink onClick={handleClickTermsOfUse}>
                        Terms of Use
                    </CustomLink>{' '}
                    and{' '}
                    <CustomLink onClick={handleCLickPrivacyPolicy}>
                        Privacy Policy
                    </CustomLink>
                </Terms>
                <Text>Don't have an account?&nbsp;
                    <Link href="/signup">Sign up -></Link>
                </Text>
            </UserInfo>
        </LoginContainer>
    );
};

export default LogInPage;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

const Link = styled.a`
    text-decoration: none;
    color: #096DD9;

`;

const Text = styled.div`
    font-family: 'Inter';
    color: #474747;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
`;

const LogoImage = styled.img`
    width: 132px;
    height: 24px;
    margin-bottom: 224px;
    margin-top: 50px;
`;

const TextHeading = styled.div`
    font-family: 'SharpGrotesk-Medium20', "Inter", sans-serif;
    font-size: 3vh;
    display: flex;
    color: #000000;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ChromeLogin = styled.div`
    margin-top: 64px;
    margin-bottom: 10px;

`;

const Terms = styled.div`
    text-align: center;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1.5vh;
    margin-top: 224px;
    margin-bottom: 24px;
`;

const CustomLink = styled.a`
    text-decoration: none;
    color: #146ef6;
    cursor: pointer;
    font-family: 'Inter';
`;
